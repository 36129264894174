import { AxiosRequestConfig } from "axios";
import {
  useAxiosPost as UsePostMutationApi,
  useAxiosGet as UseGetMutationApi,
} from "../hooks/useAxios";

const BASE_API_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost:8001";

export const getAllDomainApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = UsePostMutationApi();

  const getAllDomain = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${BASE_API_URL}/domain/get-all`, data, config);
  };

  return { getAllDomain, isLoading, isError, isSuccess };
};

export const getDomainById = () => {
  const [callApi, { isLoading, isError, isSuccess }] = UseGetMutationApi();

  const getDomainByIds = async (
    id: string | undefined,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${BASE_API_URL}/domain/get/${id}`, config);
  };

  return { getDomainByIds, isLoading, isError, isSuccess };
};

export const AddDomainApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = UsePostMutationApi();

  const addDomain = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${BASE_API_URL}/domain/create`, data, config);
  };

  return { addDomain, isLoading, isError, isSuccess };
};

export const UpdateDomainApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = UsePostMutationApi();

  const updateDomain = async (
    id: string | undefined,
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${BASE_API_URL}/domain/update/${id}`, data, config);
  };

  return { updateDomain, isLoading, isError, isSuccess };
};

export const deleteDomainApi = () => {
  const [callApi, { isLoading, isError, isSuccess }] = UsePostMutationApi();

  const deleteDomain = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${BASE_API_URL}/domain/delete`, data, config);
  };

  return { deleteDomain, isLoading, isError, isSuccess };
};
