import { useEffect, useState } from "react";
import {
  AddDomainApi,
  UpdateDomainApi,
  getDomainById,
} from "../../services/domain.service";

interface DetailProps {
  domainId?: string;
  setDomainId?: (value: string) => void;
  setIsDomainModal?: (value: boolean) => void;
  setReload?: (value: boolean) => void;
}

const AddDomain = ({
  setIsDomainModal,
  setReload,
  domainId,
  setDomainId,
}: DetailProps) => {
  const [loading, setLoading] = useState(false);
  const [nameFieldErrors, setNameFieldErrors] = useState<string[]>([]);
  const [nameFields, setNameFields] = useState<
    { id: number; domain_name: string }[]
  >([{ id: 1, domain_name: "" }]);

  const { addDomain } = AddDomainApi();
  const { updateDomain } = UpdateDomainApi();
  const { getDomainByIds } = getDomainById();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { value } = e.target;
    setNameFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, domain_name: value } : field
      )
    );
    setNameFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };

  const handleAddField = () => {
    const newId = nameFields.length + 1;
    setNameFields([...nameFields, { id: newId, domain_name: "" }]);
  };

  const handleRemoveField = (id: number) => {
    setNameFields((prevFields) =>
      prevFields.filter((field) => field.id !== id)
    );
    setNameFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };

  const addOrUpdate = async () => {
    const errors: string[] = [];
    nameFields.forEach((field, index) => {
      if (field.domain_name.trim() === "") {
        errors[field.id] = "Name cannot be empty";
      }
    });

    if (Object.keys(errors).length > 0) {
      setNameFieldErrors(errors);
      return;
    }

    if (domainId) {
      const { data, error } = await updateDomain(domainId, {
        domain_name: nameFields[0].domain_name,
      });

      if (data.responseType !== "error") {
        setIsDomainModal?.(false);
        setReload?.(true);
      }
      setLoading(false);
    } else {
      const { data, error } = await addDomain(nameFields);

      if (data.responseType !== "error") {
        setIsDomainModal?.(false);
        setReload?.(true);
      }
      setLoading(false);
    }
    setDomainId?.("");
  };

  const getDomainDataById = async (id: string) => {
    try {
      const { data } = await getDomainByIds(id);
      if (data && data.data) {
        setNameFields([
          {
            id: 1,
            domain_name: data.data.domain_name,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching domain data:", error);
    }
  };

  useEffect(() => {
    if (domainId) {
      getDomainDataById(domainId);
    }
  }, [domainId]);

  return (
    <>
      <div className="modal__wrapper__SD">
        <div className="modal__overlay__SD"></div>
        <div className="modal__content__wrapper" style={{ width: "500px" }}>
          <div className="modal__header">
            <h3 className="title">
              {domainId ? "Update Domain" : "Add Domain"}
            </h3>
            <button
              className="modal__close__btn"
              onClick={() => {
                setIsDomainModal?.(false);
                setDomainId?.("");
              }}
            ></button>
          </div>
          <div className="modal__body">
            <div className="login__form">
              {nameFields.map((field, index) => (
                <div className="repeaterFieldBox mb-3" key={field.id}>
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => handleChange(e, field.id)}
                    value={field.domain_name}
                  />
                  {nameFieldErrors[field.id] && (
                    <p className="text-danger">{nameFieldErrors[field.id]}</p>
                  )}
                  {index > 0 && (
                    <button
                      onClick={() => handleRemoveField(field.id)}
                      className="repeaterMinus__btn"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}

              {domainId ? (
                <button
                  className="btn btn-primary mr-3 d-inline-flex align-items-center"
                  onClick={() => addOrUpdate()}
                >
                  {loading ? <div className="i__ButtonLoader"></div> : ""}
                  Update
                </button>
              ) : (
                <>
                  <button
                    className="addTertaryBtn mt-1"
                    onClick={handleAddField}
                  >
                    + Add Name Fields
                  </button>
                  <div className="submit_-wrapper pt-3">
                    <button
                      className="btn btn-primary mr-3 d-inline-flex align-items-center"
                      onClick={() => addOrUpdate()}
                    >
                      {loading ? <div className="i__ButtonLoader"></div> : ""}
                      Add
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDomain;
