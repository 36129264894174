export const formatDate = (dateString: string) => {
  const utcDate = new Date(dateString);
  const options = {
    weekday: "short" as const,
    day: "numeric" as const,
    month: "short" as const,
    year: "numeric" as const,
    hour: "numeric" as const,
    minute: "numeric" as const,
    second: "numeric" as const,
    timeZoneName: "short" as const,
  };
  return utcDate.toLocaleString("en-US", options);
};
