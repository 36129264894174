import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const navigate = useNavigate();

  const Logout = (): void => {
    localStorage.clear();
    navigate("/login");
  };

  const [showModel, setShowModel] = useState(false);

  const isLinkActive = (link: string): boolean => {
    return location.pathname === link;
  };
  return (
    <>
      <div className="header header__notification">
        <a href="#default" className="logo">
          Notification Server
        </a>
        <div className="header-right">
          <Link
            to="/"
            className={
              isLinkActive("/")
                ? "s__Menu__Item__Link active"
                : "s__Menu__Item__Link"
            }
          >
            <span>Dashboard</span>
          </Link>
          <Link
            to="/mailLogs"
            className={
              isLinkActive("/mailLogs")
                ? "s__Menu__Item__Link active"
                : "s__Menu__Item__Link"
            }
          >
            <span>Mail Logs</span>
          </Link>
          <Link
            to="/bouncedMailLogs"
            className={
              isLinkActive("/bouncedMailLogs")
                ? "s__Menu__Item__Link active"
                : "s__Menu__Item__Link"
            }
          >
            <span>Bounced Mail </span>
          </Link>
          {user?.role === "ADMIN" ? (
            <Link
              to="/users"
              className={
                isLinkActive("/users")
                  ? "s__Menu__Item__Link active"
                  : "s__Menu__Item__Link"
              }
            >
              <span>Users</span>
            </Link>
          ) : (
            ""
          )}
          <Link
            to="/company"
            className={
              isLinkActive("/company")
                ? "s__Menu__Item__Link active"
                : "s__Menu__Item__Link"
            }
          >
            <span>Company</span>
          </Link>
          <Link
            to="/domain"
            className={
              isLinkActive("/domain")
                ? "s__Menu__Item__Link active"
                : "s__Menu__Item__Link"
            }
          >
            <span>Domain</span>
          </Link>
          <Link
            to="/users/change-password"
            className={
              isLinkActive("/users/change-password")
                ? "s__Menu__Item__Link active"
                : "s__Menu__Item__Link"
            }
          >
            <span>Change Password</span>
          </Link>
          <Link
            to=""
            className="s__Menu__Item__Link"
            onClick={() => setShowModel(!showModel)}
          >
            <span>Logout</span>
          </Link>
        </div>
      </div>

      <div
        className={
          "ip__Modal__Wrapper small__without__HT__modal " +
          (showModel ? "show" : "")
        }
      >
        <div className="ip__Modal__Overlay"></div>
        <div className="ip__Modal__ContentWrap">
          <div className="ip__Modal__Header">
            <h3 className="title"></h3>
            <div className="i__Icon" onClick={() => setShowModel(false)}>
              <div>
                <svg
                  width="58"
                  height="58"
                  viewBox="0 0 58 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0711 36.0712L21.9289 21.9291"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M21.9289 36.0711L29 29L36.071 21.929"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="ip__Modal__Body ip__FancyScroll relative">
            <div className="confirmation__image__wrapper">
              <img src="../deleteAnimatedIcon.gif" alt="" />
            </div>
            <h5 className="confirmation__title">
              Are you sure you want to log out?{" "}
            </h5>
          </div>
          <div className="ip__Modal__Footer">
            <button
              type="button"
              className="i__Button secondary__Btn smaller min-w-[100px]"
              onClick={() => setShowModel(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="i__Button delete__Btn smaller min-w-[100px]"
              onClick={() => Logout()}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
