import RouteComponent from "./routes/RouteComponent";
import Toast from "./components/toast";
import "./App.css"
import { createContext, useState } from "react";

export const ToastContext = createContext({
  toast: {
    type: "",
    message: "",
    showToast: false
  },
  setToast: (toast: any) => { }
});


function App() {

  const [toast, setToast] = useState({type: "",message: "",showToast: false});
  
  return (
    <>

      <ToastContext.Provider value={{toast, setToast}}>
        <RouteComponent />
        <Toast />
      </ToastContext.Provider>
    </>
  );
}

export default App;
