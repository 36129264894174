import React from "react";
import Domain from "../pages/Domain";

const Login = React.lazy(() => import("../pages/Login"));
const MailLogs = React.lazy(() => import("../pages/MailLogs/index"));
const BouncedMailLogs = React.lazy(
  () => import("../pages/BouncedMailLogs/index")
);
const AddUser = React.lazy(() => import("../pages/User/add"));
const User = React.lazy(() => import("../pages/User"));
const Company = React.lazy(() => import("../pages/Company"));
const ForgotPassword = React.lazy(() => import("../pages/Forgot-password"));
const ChangePassword = React.lazy(() => import("../pages/Change-password"));
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));

export const publicRoutes = [
  {
    path: "/login",
    name: "login",
    component: <Login />,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: <ForgotPassword />,
  },
];

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "/mailLogs",
    name: "Mail Logs",
    component: <MailLogs />,
  },
  {
    path: "/bouncedMailLogs",
    name: "Bounced Mail Logs",
    component: <BouncedMailLogs />,
  },
  {
    path: "/users",
    name: "Users",
    component: <User />,
  },
  {
    path: "/users/add",
    name: "Add User",
    component: <AddUser />,
  },
  {
    path: "/company",
    name: "Company",
    component: <Company />,
  },
  {
    path: "/domain",
    name: "Domain",
    component: <Domain />,
  },
  {
    path: "/users/change-password",
    name: "Not Found",
    component: <ChangePassword />,
  },
  {
    path: "*",
    name: "Not Found",
    component: <PageNotFound />,
  },
];

export default routes;
