import routes, { publicRoutes } from "./routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import Header from "../components/Header";
import AuthGaurd from "../components/authGaurd";


interface RouteAttribute {
  path: string;
  component: JSX.Element;
  role?: string[];
  name?: string;
}

const RouteComponent = () => {
  return (
    <Router>
      <Routes>
        {publicRoutes &&
          publicRoutes.map((route: RouteAttribute, idx: number) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                element={
                  <Suspense>
                    <AuthGaurd>
                      {route.component}
                    </AuthGaurd>
                  </Suspense>
                }
              />
            ) : null;
          })}
        {routes &&
          routes.map((route: RouteAttribute, idx: number) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                element={
                  <Suspense >
                    <Header />
                    <AuthGaurd>
                      {route.component}
                    </AuthGaurd>
                  </Suspense>
                }
              />
            ) : null;
          })}
      </Routes>
    </Router>
  );
};


export default RouteComponent;
