import React, { useEffect, useState } from "react";
import DataTable, { TableColumn, SortOrder } from "react-data-table-component";

import { debounce } from "lodash";
import {
  deleteDomainApi,
  getAllDomainApi,
} from "../../services/domain.service";
import AddDomain from "./add";
import { formatDate } from "../../helper";

interface Domain {
  id: number;
  domain_name: string;
  created_date: string;
}

type SortHandler = (
  selectedColumn: TableColumn<Domain>,
  sortDirection: SortOrder,
  sortedRows: Domain[]
) => void;

const Domain = () => {
  const [domainDetails, setDomainDetails] = useState({
    data: [] as Domain[],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState<string | undefined>("");
  const [sortOrder, setSortOrder] = useState<string | undefined>("");
  const [isDomainModal, setIsDomainModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [showModel, setShowModel] = useState(false);
  const [searchCompany, setSearchCompany] = useState("");
  const [deleteID, setDeleteID] = useState<number | null>(null);
  const [domainId, setDomainId] = useState("");

  const { getAllDomain } = getAllDomainApi();

  const { deleteDomain } = deleteDomainApi();

  useEffect(() => {
    getData(1, perPage);
  }, []);

  useEffect(() => {
    getData(page, perPage, searchCompany, sortField, sortOrder);
  }, [reload]);

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    getData(1, newPerPage, searchCompany, sortField, sortOrder);
    setPerPage(newPerPage);
  };

  const handlePageChange = (newPage: number) => {
    getData(newPage, perPage, searchCompany, sortField, sortOrder);
  };

  const handleSort: SortHandler = (selectedColumn, sortDirection) => {
    if (selectedColumn.sortField) {
      getData(
        page,
        perPage,
        searchCompany,
        selectedColumn.sortField,
        sortDirection
      );
    }
  };

  const getData = async (
    page: number,
    limit: number,
    search: string = "",
    sortField?: string,
    sortOrder?: string
  ) => {
    setLoading(true);
    const { data, error } = await getAllDomain({
      page: page,
      limit: limit,
      search: search,
      sortField: sortField,
      sortOrder: sortOrder,
    });

    setPage(page);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchCompany(search);
    setDomainDetails({ ...data.data });
    setLoading(false);
    setReload(false);
  };

  const handleDelete = async () => {
    try {
      if (deleteID) {
        const { data, error } = await deleteDomain({ id: deleteID });
        if (error) {
          console.log("error", error);
        } else {
          setDeleteID(null);
          setShowModel(false);
          getData(1, perPage, searchCompany, sortField, sortOrder);
        }
      }
    } catch (error) {
      console.error("Error deleting domain:", error);
    }
  };

  const updateCompany = (id: any) => {
    setDomainId(id);
    setIsDomainModal(true);
  };

  const getComponent = () => {
    const startIndex = (page - 1) * perPage + 1;
    const endIndex = Math.min(startIndex + perPage - 1, domainDetails.total);
    return (
      <>
        <div className="paginationWrapper">
          <div className="perPageWrapper">
            <label className="label">Rows per page:</label>
            <select
              className="pageSelect"
              value={perPage}
              onChange={(e) =>
                handlePerRowsChange(Number(e.target.value), page)
              }
            >
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
            </select>
          </div>
          <h4 className="valueWrapper">{`${startIndex}-${endIndex} of ${domainDetails.total}`}</h4>
          <div className="btnsGroup">
            <button
              className="btnWrapper"
              onClick={() => handlePageChange(1)}
              disabled={page === 1}
            >
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path
                  d="M24 13C24 13.221 23.9115 13.433 23.754 13.5893C23.5964 13.7455 23.3828 13.8333 23.16 13.8333H9.22755L15.3543 19.9104C15.4323 19.9878 15.4943 20.0798 15.5365 20.1809C15.5787 20.2821 15.6005 20.3905 15.6005 20.5C15.6005 20.6095 15.5787 20.7179 15.5365 20.8191C15.4943 20.9202 15.4323 21.0122 15.3543 21.0896C15.2763 21.167 15.1836 21.2284 15.0816 21.2703C14.9797 21.3122 14.8704 21.3338 14.76 21.3338C14.6496 21.3338 14.5403 21.3122 14.4384 21.2703C14.3364 21.2284 14.2437 21.167 14.1657 21.0896L6.6057 13.5896C6.5276 13.5122 6.46564 13.4203 6.42337 13.3191C6.3811 13.218 6.35934 13.1095 6.35934 13C6.35934 12.8905 6.3811 12.782 6.42337 12.6809C6.46564 12.5797 6.5276 12.4878 6.6057 12.4104L14.1657 4.91042C14.2437 4.83299 14.3364 4.77157 14.4384 4.72967C14.5403 4.68777 14.6496 4.6662 14.76 4.6662C14.8704 4.6662 14.9797 4.68777 15.0816 4.72967C15.1836 4.77157 15.2763 4.83299 15.3543 4.91042C15.4323 4.98784 15.4943 5.07976 15.5365 5.18092C15.5787 5.28208 15.6005 5.3905 15.6005 5.5C15.6005 5.6095 15.5787 5.71792 15.5365 5.81908C15.4943 5.92024 15.4323 6.01216 15.3543 6.08958L9.22755 12.1667H23.16C23.3828 12.1667 23.5964 12.2545 23.754 12.4107C23.9115 12.567 24 12.779 24 13ZM3.84 3C3.61722 3 3.40356 3.0878 3.24603 3.24408C3.0885 3.40036 3 3.61232 3 3.83333V22.1667C3 22.3877 3.0885 22.5996 3.24603 22.7559C3.40356 22.9122 3.61722 23 3.84 23C4.06278 23 4.27644 22.9122 4.43397 22.7559C4.5915 22.5996 4.68 22.3877 4.68 22.1667V3.83333C4.68 3.61232 4.5915 3.40036 4.43397 3.24408C4.27644 3.0878 4.06278 3 3.84 3Z"
                  fill="#343330"
                />
              </svg>
            </button>
            <button
              className="btnWrapper"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                  d="M21.6581 26.9815C21.7665 27.0913 21.8525 27.2216 21.9112 27.3651C21.9698 27.5086 22 27.6623 22 27.8176C22 27.9729 21.9698 28.1266 21.9112 28.2701C21.8525 28.4135 21.7665 28.5439 21.6581 28.6537C21.5498 28.7635 21.4211 28.8506 21.2795 28.91C21.1379 28.9694 20.9861 29 20.8328 29C20.6795 29 20.5278 28.9694 20.3862 28.91C20.2445 28.8506 20.1159 28.7635 20.0075 28.6537L8.34213 16.8361C8.23367 16.7263 8.14763 16.596 8.08892 16.4525C8.03022 16.3091 8 16.1553 8 16C8 15.8447 8.03022 15.6909 8.08892 15.5475C8.14763 15.404 8.23367 15.2737 8.34213 15.1639L20.0075 3.34632C20.2264 3.12458 20.5233 3 20.8328 3C21.1424 3 21.4393 3.12458 21.6581 3.34632C21.877 3.56807 22 3.86882 22 4.18242C22 4.49601 21.877 4.79676 21.6581 5.01851L10.8166 16L21.6581 26.9815Z"
                  fill="#343330"
                />
              </svg>
            </button>
            <button
              className="btnWrapper"
              onClick={() => handlePageChange(page + 1)}
              disabled={page * perPage >= domainDetails.total}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                  d="M23.6579 16.8361L11.9925 28.6537C11.8841 28.7635 11.7555 28.8506 11.6138 28.91C11.4722 28.9694 11.3205 29 11.1672 29C11.0139 29 10.8621 28.9694 10.7205 28.91C10.5789 28.8506 10.4502 28.7635 10.3419 28.6537C10.2335 28.5439 10.1475 28.4135 10.0888 28.2701C10.0302 28.1266 10 27.9729 10 27.8176C10 27.6623 10.0302 27.5086 10.0888 27.3651C10.1475 27.2216 10.2335 27.0913 10.3419 26.9815L21.1834 16L10.3419 5.01851C10.123 4.79676 10 4.49601 10 4.18242C10 3.86882 10.123 3.56807 10.3419 3.34632C10.5607 3.12458 10.8576 3 11.1672 3C11.4767 3 11.7736 3.12458 11.9925 3.34632L23.6579 15.1639C23.7663 15.2737 23.8524 15.404 23.9111 15.5475C23.9698 15.6909 24 15.8447 24 16C24 16.1553 23.9698 16.3091 23.9111 16.4525C23.8524 16.596 23.7663 16.7263 23.6579 16.8361Z"
                  fill="#343330"
                />
              </svg>
            </button>
            <button
              className="btnWrapper"
              onClick={() =>
                handlePageChange(Math.ceil(domainDetails.total / perPage))
              }
              disabled={page * perPage >= domainDetails.total}
            >
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path
                  d="M19.3943 12.4104C19.4724 12.4878 19.5344 12.5797 19.5766 12.6809C19.6189 12.782 19.6407 12.8905 19.6407 13C19.6407 13.1095 19.6189 13.218 19.5766 13.3191C19.5344 13.4203 19.4724 13.5122 19.3943 13.5896L11.8343 21.0896C11.7563 21.167 11.6636 21.2284 11.5616 21.2703C11.4597 21.3122 11.3504 21.3338 11.24 21.3338C11.1296 21.3338 11.0203 21.3122 10.9184 21.2703C10.8164 21.2284 10.7237 21.167 10.6457 21.0896C10.5677 21.0122 10.5057 20.9202 10.4635 20.8191C10.4213 20.7179 10.3995 20.6095 10.3995 20.5C10.3995 20.3905 10.4213 20.2821 10.4635 20.1809C10.5057 20.0798 10.5677 19.9878 10.6457 19.9104L16.7724 13.8333H2.84C2.61722 13.8333 2.40356 13.7455 2.24603 13.5893C2.0885 13.433 2 13.221 2 13C2 12.779 2.0885 12.567 2.24603 12.4107C2.40356 12.2545 2.61722 12.1667 2.84 12.1667H16.7724L10.6457 6.08958C10.4881 5.93322 10.3995 5.72114 10.3995 5.5C10.3995 5.27886 10.4881 5.06678 10.6457 4.91042C10.8033 4.75405 11.0171 4.6662 11.24 4.6662C11.4629 4.6662 11.6767 4.75405 11.8343 4.91042L19.3943 12.4104ZM22.16 3C21.9372 3 21.7236 3.0878 21.566 3.24408C21.4085 3.40036 21.32 3.61232 21.32 3.83333V22.1667C21.32 22.3877 21.4085 22.5996 21.566 22.7559C21.7236 22.9122 21.9372 23 22.16 23C22.3828 23 22.5964 22.9122 22.754 22.7559C22.9115 22.5996 23 22.3877 23 22.1667V3.83333C23 3.61232 22.9115 3.40036 22.754 3.24408C22.5964 3.0878 22.3828 3 22.16 3Z"
                  fill="#343330"
                />
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const onInputChange = debounce((e: string) => {
      setFilterText(e);
      getData(1, perPage, e);
    }, 1000);
    return (
      <>
        <div className="tableHeader">
          <h3 className="title">Domain</h3>{" "}
          <div className="rightWrapper">
            <input
              className="form-control"
              type="search"
              placeholder="Search..."
              onChange={(e) => onInputChange(e.target.value)}
            />
            <button
              className="button button-add"
              onClick={() => setIsDomainModal(true)}
            >
              Add Domain
            </button>
          </div>
        </div>
      </>
    );
  }, [filterText]);

  return (
    <>
      <div className="rdt__table__main__wrapper">
        <DataTable
          columns={[
            {
              name: "Domain Name",
              selector: (row) => row["domain_name"],
              sortable: true,
              sortField: "domain_name",
            },

            {
              name: "Created Date",
              selector: (row) => formatDate(row["created_date"]),
              sortable: true,
              sortField: "created_date",
            },
            {
              name: "Action",
              cell: (row) => (
                <>
                  <button
                    className="delete__btn"
                    onClick={() => {
                      setShowModel(true);
                      setDeleteID(row["id"]);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="edit__btn"
                    onClick={() => {
                      updateCompany(row["id"]);
                    }}
                  >
                    Edit
                  </button>
                </>
              ),
            },
          ]}
          data={domainDetails.data}
          pagination
          paginationServer
          progressPending={loading}
          paginationTotalRows={domainDetails.total}
          paginationComponent={getComponent}
          sortServer
          onSort={handleSort}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
      <div
        className={
          "ip__Modal__Wrapper small__without__HT__modal " +
          (showModel ? "show" : "")
        }
      >
        <div className="ip__Modal__Overlay"></div>
        <div className="ip__Modal__ContentWrap">
          <div className="ip__Modal__Header">
            <h3 className="title"></h3>
            <div className="i__Icon" onClick={() => setShowModel(false)}>
              <div>
                <svg
                  width="58"
                  height="58"
                  viewBox="0 0 58 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0711 36.0712L21.9289 21.9291"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M21.9289 36.0711L29 29L36.071 21.929"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="ip__Modal__Body ip__FancyScroll relative">
            <div className="confirmation__image__wrapper">
              <img src="deleteAnimatedIcon.gif" alt="" />
            </div>
            <h5 className="confirmation__title">
              Are you sure you want to delete domain?{" "}
            </h5>
          </div>
          <div className="ip__Modal__Footer">
            <button
              type="button"
              className="i__Button secondary__Btn smaller min-w-[100px]"
              onClick={() => setShowModel(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="i__Button delete__Btn smaller min-w-[100px]"
              onClick={() => handleDelete()}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      {isDomainModal && (
        <AddDomain
          setIsDomainModal={setIsDomainModal}
          setReload={setReload}
          domainId={domainId}
          setDomainId={setDomainId}
        />
      )}
    </>
  );
};

export default Domain;
