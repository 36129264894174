import { useContext } from 'react'
import { ToastContext } from './../App'


const Toast = () => {    
    const {toast, setToast} = useContext(ToastContext);
    
    return <>
        <div className={+toast.showToast? 'toast__wrapper '+toast.type+'__toast show':'toast__wrapper '+toast.type+'__toast'}>
            <div className='text'>{toast.message}</div>
            <button className='close__btn' onClick={() => {setToast({type: "",message: "",showToast: false})}}></button>
        </div>
    </>
}


export default Toast;