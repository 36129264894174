import { useEffect } from 'react'
import { useNavigate,useLocation } from "react-router-dom"
import routes from '../routes/routes';

interface Props {
    children: JSX.Element;
}


const AuthGaurd = ({ children }: Props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const user = localStorage.getItem('user') 
    useEffect(() => {
        const token = localStorage.getItem("token")
        const userData = JSON.parse(user || '{}');

        if(location.pathname !== '/forgot-password'){
            if (!token) {
                navigate("/login")
            } else {
                if (location.pathname === '/login') {
                    navigate("/")
                }else{
                    if((location.pathname === "/users" || location.pathname === "/users/add") && userData?.role !== "ADMIN"){
                        navigate("/")
                    }
                    
                }
            }
        }
        

    }, [location.pathname,user])


    return (
        <>{children}</>
    )
}

export default AuthGaurd